<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">calendar</div>
      </v-row>
      <v-row class="">
        <div class="">
          Meeting agendas are generally linked to the calendar 24 hours before
          the meeting. The marked agenda shows the actions taken on each item
          after the meeting
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <v-row class="justify-center text-capitalize" dense>
        <v-col v-for="item in menuItems" :key="item.title">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-caption">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-col>
      </v-row>

      <v-tabs
        :fixed-tabs="!isMobile"
        :vertical="isMobile"
        background-color="transparent"
        class="black--text"
        v-model="tabs"
        @change="filterSessions"
      >
        <v-tab>
          <span class="primary--text">house</span>
        </v-tab>
        <v-tab>
          <span class="green--text">committee</span>
        </v-tab>
        <v-tab>
          <span class="purple--text">joint committees</span>
        </v-tab>
        <!-- <v-tab>boards and commisions</v-tab>
        <v-tab>events</v-tab> -->
        <v-tab>
          <span class="black--text">all calendar</span>
        </v-tab>
      </v-tabs>

      <v-card-title>
        <v-text-field
          append-icon="mdi-magnify"
          clearable
          outlined
          label="Search by name"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn class="mr-2 mb-7" color="primary"
          >subscribe <v-icon right>mdi-mail</v-icon></v-btn
        >
        <v-btn class="mb-7" color="primary"
          >print <v-icon right>mdi-printer</v-icon></v-btn
        >
      </v-card-title>

      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn class="mr-4" color="primary" @click="setToday">
                Today
              </v-btn>
              <v-btn fab text small color="primary" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="primary" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :type="type"
              :event-color="getEventColor"
              @click:more="viewDay"
              @click:date="viewDay"
              @click:event="viewEvent"
            ></v-calendar>
            <v-dialog
              v-model="selectedOpen"
              :activator="selectedElement"
              width="500"
            >
              <v-card color="grey lighten-4" width="500" flat class="mx-auto">
                <v-toolbar :color="getEventColor(selectedEvent)" dark>
                  <v-toolbar-title
                    class="text-title"
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>{{ getEventIcon(selectedEvent.status) }}</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="mt-3 black--text">
                  <u class="text-uppercase font-weight-bold">
                    {{ selectedEvent.meeting_body }}
                  </u>
                  <p class="text-title">
                    <v-icon :color="getEventColor(selectedEvent)"
                      >mdi-text</v-icon
                    >
                    {{ selectedEvent.description }}
                  </p>
                  <p class="text-title">
                    <v-icon :color="getEventColor(selectedEvent)"
                      >mdi-calendar</v-icon
                    >
                    {{ selectedEvent.start | moment("calendar") }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    :color="getEventColor(selectedEvent)"
                    @click="selectedOpen = false"
                    dark
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { sessionStore } from "../stores/sessions";
export default {
  data: () => ({
    menuItems: [
      {
        title: "normal session",
        icon: "mdi-calendar",
      },
      {
        title: "updated session",
        icon: "mdi-update",
      },
      {
        title: "adjourned session",
        icon: "mdi-timer-cog-outline",
      },
      {
        title: "special seating",
        icon: "mdi-calendar-star",
      },
      {
        title: "adhock session",
        icon: "mdi-calendar-alert",
      },
      {
        title: "cancelled session",
        icon: "mdi-cancel",
      },
    ],
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    tabs: null,
    showingSessions: [],
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    ...mapActions(sessionStore, ["getSessions"]),
    getEventColor(event) {
      if (event.meeting_body == "house") return "primary";
      else if (event.meeting_body == "committee") return "green";
      else return "purple";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    viewEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    filterSessions() {
      if (this.tabs == 0)
        this.showingSessions = this.sessions.filter(
          (session) => session.meeting_body == "house"
        );
      else if (this.tabs == 1)
        this.showingSessions = this.sessions.filter(
          (session) => session.meeting_body == "committee"
        );
      else if (this.tabs == 2)
        this.showingSessions = this.sessions.filter(
          (session) => session.meeting_body == "joint"
        );
      else this.showingSessions = this.sessions;
    },
    getEventIcon(status) {
      switch (status) {
        case "normal":
          return "mdi-calendar";
        case "updated":
          return "mdi-update";
        case "adjourned":
          return "mdi-timer-cog-outline";
        case "special":
          return "mdi-calendar-star";
        case "adhock":
          return "mdi-calendar-alert";
        case "cancelled":
          return "mdi-cancel";
      }
    },
  },
  computed: {
    ...mapState(sessionStore, ["sessions"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    events() {
      let ev = [];
      for (let session of this.showingSessions) {
        ev.push({
          name: session.name,
          start: `${session.date} ${session.time}`,
          meeting_body: session.meeting_body,
          description: session.description,
          status: session.session.name,
        });
      }
      return ev;
    },
  },
  created() {
    this.getSessions();
  },
  beforeMount() {
    this.tabs = 3;
    this.showingSessions = this.sessions;
  },
};
</script>

<style></style>
