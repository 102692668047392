import { defineStore } from "pinia";
import Api from "../services/Api";

export const sessionStore = defineStore("session", {
  state: () => {
    return {
      sessions: [],
    };
  },
  getters: {
    houseSessions: (state) => {
      return state.sessions.filter((sess) => sess.meeting_body == "house");
    },
    commiteeSessions: (state) => {
      return state.sessions.filter((sess) => sess.meeting_body == "committee");
    },
    jointSessions: (state) => {
      return state.sessions.filter((sess) => sess.meeting_body == "joint");
    },
    session: (state) => {
      return state.sessions[0];
    },
  },
  actions: {
    getSessions() {
      return Api.getSessions()
        .then((res) => {
          this.sessions = res.data.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    createSession(data) {
      return Api.createSession(data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    editSession(id, data) {
      return Api.editSession(id, data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    deleteSession(id) {
      return Api.deleteSession(id)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
});
